<template>
  <div class="menu_srch d-flex" @click="showSearch = !showSearch">
    <i class="fas fa-search search_btn"></i>
    <!-- SEARCH INPUT -->
    <div class="header_serch" @click.stop v-if="showSearch">
      <div class="header_serch_input">
        <!-- <input type="" name="" v-model="str" @keyup.enter="searchBlog" placeholder="Search"> -->
        <Input
          v-model="str"
          @on-keyup.enter="searchBlog"
          placeholder="Search"
        />
      </div>
      <div class="header_serch_i" @click="searchBlog">
        <i class="fas fa-search"></i>
      </div>
    </div>
    <!-- SEARCH INPUT -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSearch: false,
      str: "",
    };
  },
  methods: {
    searchBlog() {
      // console.log(this.str);
      if (this.str.trim() == "") return;
      window.location = `/search?str=${this.str}`;
    },
  },
};
</script>

<style>
</style>