<template>
  <div class="comment-root">
    <div class="row2">
      <div class="col-12 col-md-12 col-lg-12">
        <h4>
          <i class="fa fa-comments" aria-hidden="true"></i>
          {{ comments.length }} comments
        </h4>

        <div v-for="comment in comments">
          <div class="comment">
            <div class="author-info">
              <img :src="gravatar(comment.email)" class="author-image" />

              <div class="author-name">
                <a href="">{{ comment.name }}</a>
                <p>{{ formatDate(comment.created_at) }}</p>
              </div>
            </div>

            <div class="comment-content">
              {{ comment.body }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Comment BOX -->
    <div class="cmnt_frm mar_t30">
      <h4 class="post_dtls_title2 pad_b20">Leave A Comment</h4>
      <div class="cmnt_frm_all">
        <div class="row2">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="cmnt_input">
              <p>Name</p>
              <Input
                type="text"
                v-model="data.name"
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="cmnt_input">
              <p>E-mail</p>
              <Input
                type="email"
                v-model="data.email"
                placeholder="Enter your E-mail"
              />
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12">
            <div class="cmnt_input">
              <p class="mar_b10">Message</p>
              <Input
                v-model="data.body"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5 }"
                placeholder="Type your comment"
              />
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12">
            <Button class="btn1" type="primary" @click="commentBlog"
              >Send message</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
import moment from "moment";

export default {
  props: {
    apiData: Object,
  },
  data() {
    return {
      data: {
        name: "",
        email: "",
        body: "",
      },
      comments: [],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
    gravatar(email) {
      const hash = md5(email.trim().toLowerCase());
      return `https://www.gravatar.com/avatar/${hash}?s=50&d=robohash`;
    },
    async commentBlog() {
      console.log(this.data);

      const res = await this.callApi(
        "post",
        `comments/${this.apiData.id}`,
        this.data
      );

      if (res.status === 201) {
        this.comments.unshift(res.data);

        this.data.name = "";
        this.data.email = "";
        this.data.body = "";
      } else {
        if (res.status == 422) {
          for (let i in res.data.errors) {
            this.e(res.data.errors[i][0]);
          }
        } else {
          this.swr();
        }
      }
    },
  },
  async created() {
    const res = await this.callApi("get", `get_comments/${this.apiData.id}`);
    if (res.status == 200) {
      this.comments = res.data;
    } else {
      this.swr();
    }
  },
};
</script>

<style>
.row2{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;;
}
</style>