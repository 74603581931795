<template>
  <div class="col-lg-6 col-md-12 site-title">
    <div class="col-12 col-md-6 col-lg-6">
      <h2 class="about-title">Contact me</h2>
    </div>

    <div class="col-12 col-md-6 col-lg-6">
      <div class="cmnt_input">
        <p>Name</p>
        <Input type="text" v-model="data.name" placeholder="Enter your name" />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
      <div class="cmnt_input">
        <p>E-mail</p>
        <Input
          type="email"
          v-model="data.email"
          placeholder="Enter your E-mail"
        />
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-12">
      <div class="cmnt_input">
        <p class="mar_b10">Message</p>
        <Input
          v-model="data.body"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
          placeholder="Type your message"
        />
      </div>

      <Button type="primary" @click="sendContact">Send</Button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      data: {
        name: "",
        email: "",
        body:"",
        message: "",
        subject: "CONTACT FROM BLOG",
      },
    };
  },
  methods: {
    sendContact() {
      this.data.message = `Email: ${this.data.email}\nName: ${this.data.name}\nMessage: ${this.data.body}`;

      axios
        .post("/submitContact", this.data)
        .then((response) => {
          //alert("Message sent!");
          this.data = {};
          // event.target.classList.remove("was-validated");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>